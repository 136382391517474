/* add css module styles here (optional) */

._GzYRV {
  line-height: 1.2;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

._3eOF8 {
  margin-right: 5px;
  font-weight: bold;
}

._f10Tu {
  font-size: 1.2em;
  margin-right: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

._1MFti {
  cursor: pointer;
}

._2IvMF {
  background: #eee;
}

._16FAf {
}

._2bkNM {
  margin: 0 10px;
  padding: 0;
}

._1MGIk {
  font-weight: 600;
  margin-right: 5px;
}

._3uHL6 {
}

._2T6PJ {
  color: #df113a;
}

._1Gho6 {
  color: #df113a;
}

._vGjyY {
  color: rgb(42, 63, 60);
}

._1bQdo {
  color: #0b75f5;
}

._3zQKs {
  color: rgb(70, 144, 56);
}

._1xvuR {
  color: #43413d;
}

._11RoI {
  background: rgb(0, 43, 54);
}

._3cpys {
  font-size: 1.2em;
  color: rgb(253, 246, 227);
}

._2bSDX {
  font-weight: bolder;
  margin-right: 5px;
  color: rgb(253, 246, 227);
}

._gsbQL {
  color: rgb(253, 246, 227);
}

._LaAZe {
  color: rgb(129, 181, 172);
}

._GTKgm {
  color: rgb(129, 181, 172);
}

._Chy1W {
  color: rgb(203, 75, 22);
}

._2bveF {
  color: rgb(211, 54, 130);
}

._2vRm- {
  color: rgb(174, 129, 255);
}

._1prJR {
  color: rgb(38, 139, 210);
}
